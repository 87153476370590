import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const Dropdown = ({ label, options, onSelect, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(label);
  const dropdownRef = useRef(null);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (index, option) => {
    onSelect(index, option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative col-span-2 text-left" ref={dropdownRef}>
      <div className="flex justify-center w-full">
        <button
          className="inline-flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption}
          <ChevronDownIcon
            className={`w-5 h-5 ml-2 transition-transform transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="py-1">
            {options.map((option, index) => (
              <button
                key={index}
                className={`block w-[90%] px-4 py-2 m-2 text-sm text-left ${
                  selectedOption === option
                    ? "text-white bg-black"
                    : "text-gray-700 bg-white"
                } border border-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-md`}
                onClick={() => handleSelect(index, option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
