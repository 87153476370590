import React from "react";
import CircularProgress from "../../../components/CircularProgress";

const StatIndicator = ({ label, percent }) => {
  return (
    <div className="flex flex-col items-center">
      <CircularProgress
        percent={percent}
        size={60}
        strokeWidth={5}
        variant="sm"
        percentColor="#FFF"
        primary="#FFF"
        secondary="#85AA80"
        dy="0.5em"
      />
      <p className="text-white text-sm mt-1">{label.toUpperCase()}</p>
    </div>
  );
};

const ZonePercentages = ({ kickTypeId, stats }) => {
  console.log("KickType: ", kickTypeId);
  return kickTypeId === 3 ? (
    <>
      <div className="flex justify-between w-full mb-4">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <StatIndicator
              label={stats[2].label}
              percent={stats[2].percentage}
            />
          </div>
          <div className="flex space-x-4">
            <StatIndicator
              label={stats[3].label}
              percent={stats[3].percentage}
            />
            <StatIndicator
              label={stats[4].label}
              percent={stats[4].percentage}
            />
          </div>
        </div>

        <div className="flex flex-col items-center">
          <div className="mb-4">
            <StatIndicator
              label={stats[1].label}
              percent={stats[1].percentage}
            />
          </div>
          <StatIndicator label={stats[0].label} percent={stats[0].percentage} />
        </div>

        <div className="flex flex-col items-center">
          <div className="mb-4">
            <StatIndicator
              label={stats[5].label}
              percent={stats[5].percentage}
            />
          </div>
          <div className="flex space-x-4">
            <StatIndicator
              label={stats[6].label}
              percent={stats[6].percentage}
            />
            <StatIndicator
              label={stats[7].label}
              percent={stats[7].percentage}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="flex justify-between w-full mb-4">
      {stats.map((zone) => (
        <StatIndicator
          key={zone.label}
          label={zone.label}
          percent={zone.percentage}
        />
      ))}
    </div>
  );
};

export default ZonePercentages;
