// src/components/UserForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { createUser, getGroups, getTeams } from "../../../utils/api";

const UserForm = ({
  fetchPlayerProfiles,
  fetchUsers,
  groups,
  setIsFormVisible,
}) => {
  const [teams, setTeams] = useState([]);
  const [email, setEmail] = useState("");
  const [group, setGroup] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [errors, setErrors] = useState({});

  const fetchTeams = async () => {
    const _teams = await getTeams();
    setTeams(_teams);
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!email) errors.email = "Email is required";
    if (!group) errors.group = "Group is required";
    if (group.name === "player") {
      if (!playerName) errors.playerName = "Player name is required";
      if (selectedTeams.length === 0)
        errors.teams = "At least one team must be selected";
      if (!profileImage) errors.profileImage = "Profile image is required";
    }
    if (errors.length > 0) {
      console.log("Errors: ", errors);
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const userData = {};
    userData.email = email;
    userData.groups = [group.id];
    if (group.name === "player") {
      userData.player_profile = {};
      userData.player_profile.name = playerName;
      userData.player_profile.organisation = 1;
      userData.player_profile.teams = [];
      selectedTeams.forEach((team, index) =>
        userData.player_profile.teams.push(team)
      );
    }

    await createUser(userData, profileImage);

    /// Reset form
    fetchUsers(); // Refresh the user list
    fetchPlayerProfiles(); //Refresh the player profiles
    setEmail("");
    setErrors({});
    setGroup(null);
    setPlayerName("");
    setSelectedTeams([]);
    setProfileImage(null);
    setIsFormVisible(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-lg mx-auto bg-white p-8 shadow-md rounded-md"
    >
      <h2 className="text-2xl font-semibold mb-6">Create User</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-kicker-green-700"
        />
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Role</label>
        <select
          value={group ? group.name : ""}
          onChange={(e) => {
            const selectedGroup = groups.find(
              (grp) => grp.name === e.target.value
            );
            setGroup(selectedGroup || null);
          }}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
        >
          <option value="">Select a role</option>
          {groups.map((group) => (
            <option key={group.name} value={group.name}>
              {group.name.charAt(0).toUpperCase() + group.name.slice(1)}
            </option>
          ))}
        </select>
      </div>
      {group.name === "player" && (
        <>
          <div className="mb-4">
            <label className="block text-gray-700">Player Name</label>
            <input
              type="text"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-kicker-green-700"
            />
            {errors.playerName && (
              <p className="text-red-500 text-sm">{errors.playerName}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Teams</label>
            <select
              multiple
              value={selectedTeams}
              onChange={(e) =>
                setSelectedTeams(
                  Array.from(e.target.selectedOptions, (option) => option.value)
                )
              }
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-kicker-green-700"
            >
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
            {errors.teams && (
              <p className="text-red-500 text-sm">{errors.teams}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Profile Image</label>
            <input
              type="file"
              onChange={(e) => setProfileImage(e.target.files[0])}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-kicker-green-700"
            />
            {errors.profileImage && (
              <p className="text-red-500 text-sm">{errors.profileImage}</p>
            )}
          </div>
        </>
      )}
      <button
        type="submit"
        className="w-full py-2 px-4 bg-kicker-green-700 text-white font-semibold rounded-md shadow-sm hover:bg-kicker-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        Create User
      </button>
    </form>
  );
};

export default UserForm;
