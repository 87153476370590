const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-4 h-4 mr-4"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
);

const SessionTypeSelection = ({
  changeStep,
  selectedSession,
  toggleSession,
}) => {
  return (
    <div>
      <div className="bg-white py-4 px-4 border justify-start border-gray-300 rounded-lg">
        <div className="text-md text-slate-400 mb-4">Select Session</div>
        <div className="flex flex-col space-y-2">
          <button
            className={`flex items-center w-[200px] px-4 py-2 border rounded-lg ${
              selectedSession === "Training"
                ? "bg-kicker-green-700 text-white"
                : "bg-white"
            }`}
            onClick={() => toggleSession("Training")}
          >
            {selectedSession === "Training" && checkmark}
            Training
          </button>
          <button
            className={`flex items-center px-4 py-2 border rounded-lg ${
              selectedSession === "Game"
                ? "bg-kicker-green-700 text-white"
                : "bg-white"
            }`}
            onClick={() => toggleSession("Game")}
          >
            {selectedSession === "Game" && checkmark}
            Game
          </button>
        </div>
      </div>
      <button
        className="absolute bottom-32 right-16 bg-kicker-green-700 text-white px-6 py-2 rounded-lg shadow"
        onClick={() => changeStep(2)}
      >
        Next
      </button>
    </div>
  );
};

export default SessionTypeSelection;
