import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { getPlayerProfiles } from "../utils/api";

const DropdownMultiSelect = ({ label, onSelect, playerFilter = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const data = await getPlayerProfiles();
      // Adjust here to store both id and name
      const playerOptions = data.map((player) => ({
        id: player.id,
        name: player.name,
      }));
      playerOptions.sort((a, b) => a.name.localeCompare(b.name));
      setOptions(playerOptions);
    }
    if (playerFilter) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    const isAlreadySelected = selectedOptions.includes(option.id);
    const newSelectedOptions = isAlreadySelected
      ? selectedOptions.filter((id) => id !== option.id)
      : [...selectedOptions, option.id];

    setSelectedOptions(newSelectedOptions);
    onSelect(newSelectedOptions); // Update to pass IDs
  };

  return (
    <div className="relative col-span-3 text-left" ref={dropdownRef}>
      <div className="flex justify-center w-full">
        <button
          className="inline-flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOptions.length
            ? `${selectedOptions.length} players selected`
            : label}
          <ChevronDownIcon
            className={`w-5 h-5 ml-2 transition-transform transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="py-1">
            {options.map((option) => (
              <button
                key={option.id}
                className={`block w-[90%] px-4 py-2 m-2 text-sm text-left ${
                  selectedOptions.includes(option.id)
                    ? "text-white bg-black focus:bg-black"
                    : "text-gray-700 bg-white hover:bg-gray-100 focus:bg-gray-100"
                } border border-black rounded-md`}
                onClick={() => handleSelect(option)}
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMultiSelect;
