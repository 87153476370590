// src/components/Modal.js
import React from "react";

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  const handleBarrierClick = (e) => {
    // Prevents clicks inside the modal from closing it
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center pb-24"
      onClick={handleBarrierClick}
    >
      <div className="relative p-5 border w-96 shadow-lg rounded-md bg-white m-24">
        <button
          className="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
