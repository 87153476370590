const updateInitialPosition = (
  imgRef,
  marginX,
  setFieldCenter,
  setFieldZones,
  setKickPosition,
  setLoaded,
  setPosition,
  setRestartZones,
  setKickForTouchZones,
  isSession
) => {
  if (imgRef.current) {
    const img = imgRef.current.getBoundingClientRect();
    const parentDiv = imgRef.current.parentElement?.getBoundingClientRect();
    // Ensure parentDiv exists

    const parentWidth = parentDiv.width;
    // console.log(`parent (w,h): ${parentDiv.width}, ${parentDiv.height}`);
    // console.log(`parent ratio: ${parentDiv.width / parentDiv.height}`);
    // dashboard img ratio: 1.7741549621855224
    // session   img ratio: 1.7741559485530547
    // console.log(`img (w,h): ${img.width}, ${img.height}`);
    // console.log(`img ratio: ${img.width / img.height}`);
    //Parent Width: 929 Parent Height: 471.25
    //Image Width: 836.09375 Image Height: 471.25

    const margin = (marginX ?? 0) * parentWidth;
    const leftEdgeOfImg = margin;
    const rightEdgeOfImg = parentWidth - margin;
    const leftOutOfBounds = 0.05457340507 * img.width;
    const rightOutOfBounds = 0.06002843966 * img.width;
    const left5mWidth = 0.08556 * img.width;
    const left15mWidth = 0.1758466258 * img.width;
    const centerBoxWidth = left5mWidth * 2.5;
    const boxHeight = img.height * 0.2;
    const leftSideline = leftEdgeOfImg + leftOutOfBounds;
    const bottomZoneY = img.height * 0.555;
    const topZoneY = img.height * 0.26;
    const rightSideLine = rightEdgeOfImg - rightOutOfBounds;

    // Kick for Touch Zones
    const kftLeftSideLine = 0.05765742879 * img.width;
    const outerZoneWidth = 0.2586605081 * img.width;
    const innerZoneWidth = 0.3618167821 * img.width;
    const bottomZoneHeight = innerZoneWidth;
    const middleZoneLowerHeight = 0.3723404255 * bottomZoneHeight;
    const middleZoneUpperHeight = 0.4808510638 * bottomZoneHeight;
    const upperZoneHeight = 0.4106382979 * bottomZoneHeight;
    const kftBottomZoneY = 0.5686427457 * img.height;

    // Set restart zone positions
    // session = img (w,h): 689.703125, 388.75
    // dashboard = img (w,h): 718.421875, 404.9375
    const restartZoneInfo = [
      {
        id: "l_4",
        label: "4",
        kickingArea: 5,
        x: leftSideline,
        y: topZoneY,
        w: left5mWidth * 2,
        h: boxHeight,
      },
      {
        id: "l_3",
        label: "3",
        kickingArea: 4,
        x: leftSideline,
        y: bottomZoneY,
        w: left5mWidth,
        h: boxHeight,
      },
      {
        id: "l_2",
        label: "2",
        kickingArea: 3,
        x: leftSideline + left5mWidth,
        y: bottomZoneY,
        w: left15mWidth,
        h: boxHeight,
      },
      {
        id: "c_5",
        label: "5",
        kickingArea: 2,
        x: img.width * 0.5 + leftEdgeOfImg - centerBoxWidth / 2,
        y: topZoneY,
        w: centerBoxWidth,
        h: boxHeight,
      },
      {
        id: "c_1",
        label: "1",
        kickingArea: 1,
        x: img.width * 0.5 + leftEdgeOfImg - centerBoxWidth / 2,
        y: bottomZoneY,
        w: centerBoxWidth,
        h: boxHeight,
      },
      {
        id: "r_4",
        label: "4",
        kickingArea: 8,
        x: rightSideLine - left5mWidth * 2,
        y: topZoneY,
        w: left5mWidth * 2,
        h: boxHeight,
      },
      {
        id: "r_3",
        label: "3",
        kickingArea: 7,
        x: rightSideLine - left5mWidth,
        y: bottomZoneY,
        w: left5mWidth,
        h: boxHeight,
      },
      {
        id: "r_2",
        label: "2",
        kickingArea: 6,
        x: rightSideLine - left5mWidth - left15mWidth,
        y: bottomZoneY,
        w: left15mWidth,
        h: boxHeight,
      },
    ];

    setRestartZones(restartZoneInfo);

    if (setKickForTouchZones) {
      const kickForTouchZones = [
        {
          id: "b_l",
          label: "",
          x: kftLeftSideLine,
          y: kftBottomZoneY,
          w: outerZoneWidth,
          h: bottomZoneHeight,
        },
        {
          id: "b_c",
          label: "",
          x: kftLeftSideLine + outerZoneWidth,
          y: kftBottomZoneY,
          w: innerZoneWidth,
          h: bottomZoneHeight,
        },
        {
          id: "b_r",
          label: "",
          x: kftLeftSideLine + outerZoneWidth + innerZoneWidth,
          y: kftBottomZoneY,
          w: outerZoneWidth,
          h: bottomZoneHeight,
        },
        {
          id: "m_l_l",
          label: "",
          x: kftLeftSideLine,
          y: kftBottomZoneY - middleZoneLowerHeight,
          w: outerZoneWidth,
          h: middleZoneLowerHeight,
        },
        {
          id: "m_l_c",
          label: "",
          x: kftLeftSideLine + outerZoneWidth,
          y: kftBottomZoneY - middleZoneLowerHeight,
          w: innerZoneWidth,
          h: middleZoneLowerHeight,
        },
        {
          id: "m_l_r",
          label: "",
          x: kftLeftSideLine + outerZoneWidth + innerZoneWidth,
          y: kftBottomZoneY - middleZoneLowerHeight,
          w: outerZoneWidth,
          h: middleZoneLowerHeight,
        },
        {
          id: "m_u_l",
          label: "",
          x: kftLeftSideLine,
          y: kftBottomZoneY - middleZoneLowerHeight - middleZoneUpperHeight,
          w: outerZoneWidth,
          h: middleZoneUpperHeight,
        },
        {
          id: "m_u_c",
          label: "",
          x: kftLeftSideLine + outerZoneWidth,
          y: kftBottomZoneY - middleZoneLowerHeight - middleZoneUpperHeight,
          w: innerZoneWidth,
          h: middleZoneUpperHeight,
        },
        {
          id: "m_u_r",
          label: "",
          x: kftLeftSideLine + outerZoneWidth + innerZoneWidth,
          y: kftBottomZoneY - middleZoneLowerHeight - middleZoneUpperHeight,
          w: outerZoneWidth,
          h: middleZoneUpperHeight,
        },
        {
          id: "t_z",
          label: "",
          x: kftLeftSideLine,
          y:
            kftBottomZoneY -
            middleZoneLowerHeight -
            middleZoneUpperHeight -
            upperZoneHeight,
          w: innerZoneWidth + outerZoneWidth * 2,
          h: upperZoneHeight,
        },
      ];

      console.log("set kick for touch zones");
      setKickForTouchZones(kickForTouchZones);
    }

    if (isSession) {
      // Set Field Zones (eg Left 5m, Left 15m, Center, Right 15m , Right 5m)
      const _fieldZones = {
        left5m: { x1: leftSideline, x2: leftSideline + left5mWidth },
        left15m: {
          x1: leftSideline + left5mWidth,
          x2: leftSideline + left5mWidth + left15mWidth,
        },
        center: {
          x1: leftSideline + left5mWidth + left15mWidth,
          x2: rightSideLine - left5mWidth - left15mWidth,
        },
        right15m: {
          x1: rightSideLine - left5mWidth - left15mWidth,
          x2: rightSideLine - left5mWidth,
        },
        right5m: { x1: rightSideLine - left5mWidth, x2: rightSideLine },
      };

      setFieldZones(_fieldZones);
      setKickPosition({
        x1: 0.5,
        y1: 0.5,
        x2: 0.6,
        y2: 0.5,
      });
      setFieldCenter({
        x: 0.5 - 16 / img.width,
        y: 0.5,
      });
      setPosition({
        x: leftEdgeOfImg + img.width * 0.5 - 15,
        y: img.height / 2 - 15,
        x2: img.width * 0.55 - 16 + 70,
        y2: img.height / 2 - 16,
      });
    }
    setLoaded(true);
  }
};

export function getZone(fieldZones, x) {
  if (x >= fieldZones.left5m.x1 && x < fieldZones.left5m.x2) {
    return "Left 5m";
  } else if (x >= fieldZones.left15m.x1 && x < fieldZones.left15m.x2) {
    return "Left 15m";
  } else if (x >= fieldZones.center.x1 && x < fieldZones.center.x2) {
    return "Center";
  } else if (x >= fieldZones.right15m.x1 && x < fieldZones.right15m.x2) {
    return "Right 15m";
  } else if (x >= fieldZones.right5m.x1 && x <= fieldZones.right5m.x2) {
    return "Right 5m";
  }
  return "Out of Bounds";
}

export default updateInitialPosition;
