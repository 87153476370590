// Function to check if the token is valid (simplified example)
export const isValidToken = () => {
  const token = localStorage.getItem("authToken");
  return !!token;
};

export const getUserId = () => {
  const userId = localStorage.getItem("userId");
  return userId;
};
