import React, { useEffect, useState } from "react";
import { getPlayerProfiles, getSessions } from "../../../utils/api";
import CircularProgress from "../../../components/CircularProgress";
import { formatDate } from "../../../utils/date_helpers";

const RecentSessions = ({ kicks, playersFilter }) => {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        /// identify all the unique kick.session ids (eg kick.session = 1)
        let sessionIds = [];
        const reducedSessions = await kicks?.reduce((sessionData, kick) => {
          if (kick.session) {
            if (!sessionData[kick.session]) {
              sessionData[kick.session] = {
                players: [],
                kicks: [],
                date: kick.created_at,
                totalKicks: 0,
                successfulKicks: 0,
              };
            }
            sessionData[kick.session].kicks.push(kick);
            sessionData[kick.session].totalKicks += 1;
            if (kick.success) {
              sessionData[kick.session].successfulKicks += 1;
            }
          }
          sessionIds.push(kick.session);
          return sessionData;
        }, []);

        // Fetch the sessions from the API and add the session.session_type to reducedSessions[session.id].session_type
        const sessionsResponse = await getSessions(sessionIds);
        sessionsResponse.forEach((session) => {
          if (reducedSessions[session.id]) {
            reducedSessions[session.id].session_type = session.session_type;
            reducedSessions[session.id].session_type_title =
              session.session_type_title;
            reducedSessions[session.id].players = session.players;
            reducedSessions[session.id].successRate =
              reducedSessions[session.id].totalKicks > 0
                ? Math.round(
                    (reducedSessions[session.id].successfulKicks /
                      reducedSessions[session.id].totalKicks) *
                      100
                  )
                : 0;
          }
        });

        // Fetch player profiles then add replace players with their player profile

        const playerProfiles = await getPlayerProfiles();

        Object.keys(reducedSessions).forEach((sessionId) => {
          reducedSessions[sessionId].players = reducedSessions[
            sessionId
          ].players.map((playerId) => {
            return (
              playerProfiles.find((profile) => profile.id === playerId) ||
              playerId
            );
          });
        });

        reducedSessions.sort((a, b) => new Date(a.date) - new Date(b.date));
        setSessions(reducedSessions);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [kicks]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-[20%] bg-white border mt-4 rounded-lg shadow-md p-4 mb-32 overflow-y-auto">
      <ul className="divide-y divide-gray-200">
        {sessions?.map((session, index) => (
          <li key={index} className="py-3 flex items-center justify-between">
            {/* Left Column */}
            <div className="flex-1">
              <h5>{session.session_type_title}</h5>
              <p className="text-gray-600">{formatDate(session.date)}</p>
            </div>

            {/* Middle Column */}
            <div className="flex-1 flex flex-col items-start">
              <p className="text-gray-600 mb-2">{session.totalKicks} kicks</p>
              <div className="flex overflow-x-auto">
                {session.players.map((player, playerIndex) => (
                  <div
                    key={playerIndex}
                    className="rounded-full bg-gray-300 w-4 h-4 mr-1 overflow-hidden flex justify-center items-center"
                  >
                    <img
                      src={player.image}
                      alt={player.name}
                      className="object-cover min-w-full min-h-full"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Right Column */}
            <div className="flex-none">
              <CircularProgress
                percent={session.successRate}
                size={40}
                strokeWidth={1}
                variant="xs"
                percentColor="#000"
                primary="#000"
                secondary="#E0E0E0"
                dy="0.4em"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentSessions;
