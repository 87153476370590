import axios from "axios";
import { getUserId } from "./auth";
const baseAPIUrl = "https://kicker-teams-backend-b44c994bcf9b.herokuapp.com/";

const authInstance = axios.create({
  baseURL: baseAPIUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the auth token
authInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const login = async (email, password) => {
  try {
    const response = await axios.post(
      baseAPIUrl + "login/",
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;

    // Assuming your token is returned in data.authToken
    localStorage.setItem("authToken", data.authToken);
    localStorage.setItem("userId", data.userId);
    return true;
  } catch (error) {
    console.error("Login error:", error);
    // Axios wraps the response error; accessing the actual error response
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      alert("Login failed. Please check your credentials.");
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    alert("An error occurred. Please try again later.");
    return false;
  }
};

export const getKicks = async (
  kick_type,
  startDate,
  endDate,
  playerIds,
  sessionType,
  zone
) => {
  // Initialize an empty array to hold query parts
  const queryParts = [];

  // Append each filter to the queryParts array if it's not null
  if (kick_type !== null) {
    queryParts.push(`kick_type=${kick_type}`);
  }
  if (startDate) {
    // Assuming startDate is a JavaScript Date object, format it as needed
    queryParts.push(`start_date=${startDate.toISOString().split("T")[0]}`);
  }
  if (endDate) {
    // Assuming endDate is a JavaScript Date object, format it as needed
    queryParts.push(`end_date=${endDate.toISOString().split("T")[0]}`);
  }
  if (playerIds !== null) {
    const playersFilter = playerIds.join(",");
    queryParts.push(`player=${playersFilter}`);
  }
  if (sessionType !== null) {
    queryParts.push(`session_type=${sessionType}`);
  }
  if (zone !== null) {
    queryParts.push(`zone=${encodeURIComponent(zone)}`);
  }

  // Join all parts with '&' to form the final query string
  const queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
  const requestUrl = baseAPIUrl + "kicks/" + queryString;
  console.log("query: ", requestUrl);
  try {
    const response = await axios.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPlayerKickSummaries = async (
  kick_type,
  startDate,
  endDate,
  playerIds,
  sessionType
) => {
  // Initialize an empty array to hold query parts
  const queryParts = [];

  // Append each filter to the queryParts array if it's not null
  if (kick_type !== null) {
    queryParts.push(`kick_type=${kick_type}`);
  }
  if (startDate) {
    // Assuming startDate is a JavaScript Date object, format it as needed
    queryParts.push(`start_date=${startDate.toISOString().split("T")[0]}`);
  }
  if (endDate) {
    // Assuming endDate is a JavaScript Date object, format it as needed
    queryParts.push(`end_date=${endDate.toISOString().split("T")[0]}`);
  }
  if (playerIds !== null) {
    const playersFilter = playerIds.join(",");
    queryParts.push(`player=${playersFilter}`);
  }
  if (sessionType !== null) {
    queryParts.push(`session_type=${sessionType}`);
  }

  // Join all parts with '&' to form the final query string
  const queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
  const requestUrl = baseAPIUrl + "player_summary/" + queryString;
  console.log("query: ", requestUrl);
  try {
    const response = await axios.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPlayerProfiles = async (id) => {
  let requestUrl = baseAPIUrl + "player_profiles/";
  if (id) {
    requestUrl = requestUrl + `${id}/`;
    console.log("player profile url: ", requestUrl);
  }
  try {
    const response = await axios.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updatePlayerProfile = async (profileId, userData) => {
  try {
    const requestUrl = baseAPIUrl + `player_profiles/${profileId}/`;
    console.log("updateUser playerProfile data: ", userData);

    const response = await authInstance.patch(requestUrl, userData);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating player profile:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getGroups = async () => {
  const requestUrl = baseAPIUrl + "groups/";
  try {
    const response = await authInstance.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getTeams = async () => {
  const requestUrl = baseAPIUrl + "teams/";
  try {
    const response = await authInstance.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getUsers = async () => {
  const requestUrl = baseAPIUrl + "users/";
  try {
    const response = await authInstance.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const requestUrl = baseAPIUrl + `users/${userId}/`;
    console.log("updateUser request url: ", requestUrl);
    console.log("updateUser userData: ", userData);

    const response = await authInstance.put(requestUrl, userData);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating user:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const createUser = async (userData, profileImage) => {
  try {
    const requestUrl = baseAPIUrl + `users/`;
    console.log("createUser userData: ", userData);

    const response = await authInstance.post(requestUrl, userData);
    console.log("create user resp: ", response.data);
    if (response.data.id) {
      uploadProfileImage(profileImage, response.data.id);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error creating user:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getSessions = async (sessionIds) => {
  const requestUrl = baseAPIUrl + "sessions/";
  const params = sessionIds
    ? {
        // Convert the array to a JSON string and ensure it's encoded
        sessionIds: JSON.stringify(sessionIds),
      }
    : null;
  try {
    const response = await axios.get(requestUrl, {
      params: params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const createSession = async (sessionType, playerIds) => {
  // Session Types (1: Training, 2: Game)
  const requestUrl = baseAPIUrl + "sessions/";
  try {
    const response = await axios.post(
      requestUrl,
      {
        created_by: 1,
        players: playerIds,
        session_type: sessionType,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const createKick = async (session, kick) => {
  // Kick Types (1: Goal Kick, 2: Drop Kick, 3: Restart, 4: Box kick, 5: Kick for Touch)
  const kickData = {
    created_by: getUserId() ?? 1,
    player: kick.player.id,
    organisation: 1,
    session: session.id,
    wind_direction: kick.windDirection,
    kick_type: kick.kickType,
    box_kick_type: kick.boxKickType,
    tee_type: "Low",
    total_distance: kick.distanceToPost,
    distance_gained:
      kick.kickType === 5 ? kick.distanceGained : kick.distanceToPost,
    success: kick.success,
    result_type: kick.resultType,
    kicking_area: kick.kickingArea,
    zone: kick.zone ?? "Center",
    x1: kick.x1 * 100,
    y1: kick.y1 * 100,
    x2: kick.kickType === 5 ? kick.x2 * 100 : null,
    y2: kick.kickType === 5 ? kick.y2 * 100 : null,
  };
  const requestUrl = baseAPIUrl + "kicks/";
  try {
    const response = await axios.post(requestUrl, kickData);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const deleteKick = async (kickId) => {
  const requestUrl = baseAPIUrl + `kicks/${kickId}/`;
  try {
    const response = await axios.delete(requestUrl);
    console.log("kick deleted response: ", response.data);
  } catch (error) {
    console.log("delete kick error: ", error);
  }
};

export const uploadProfileImage = async (file, userId) => {
  if (file) {
    console.log("uploading file");
    const formData = new FormData();
    formData.append("profile_image", file);
    formData.append("userId", userId);
    const requestUrl = baseAPIUrl + "upload-profile-image/";
    try {
      const response = await authInstance.post(requestUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("res upload image: ", response);
      return response.data.image_url;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
};
