import React from "react";

const KickAddedTile = () => {
  return (
    <div className="fade-out-animation flex items-center px-4 py-4 col-span-2 border rounded-lg text-sm space-x-2 bg-kicker-green-700 text-white">
      <p>Kick Added!</p>
    </div>
  );
};

export default KickAddedTile;
