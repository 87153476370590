import React, { useState, useRef, useEffect, useCallback } from "react";
import { createKick } from "../../../utils/api";
import updateInitialPosition, { getZone } from "../../../utils/map_helpers";

const KickDataEntryField1 = ({
  boxKickType,
  kickType,
  player,
  src,
  setDistanceToCenter,
  setDistanceToPost,
  setDistanceToTryLine,
  setLastKick,
  setMetersGained,
  session,
}) => {
  const [fieldCenter, setFieldCenter] = useState({ x: 0, y: 0 });
  const [fieldZones, setFieldZones] = useState(null);
  const [kickPosition, setKickPosition] = useState({ x1: 0, y1: 0 });
  const [loaded, setLoaded] = useState(false);
  const [markerOneIsOutOfBounds, setMarkerOneIsOutOfBounds] = useState(false);
  const [markerTwoIsOutOfBounds, kickForTouchIsGood] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [restartZones, setRestartZones] = useState(null);
  const [restartZoneSelected, setRestartZoneSelected] = useState("c_5");
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const imgRef = useRef(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [showDialog, setShowDialog] = useState(false);
  const [currentKick, setCurrentKick] = useState(null);

  const [isTouchActive, setIsTouchActive] = useState(false);
  const [isMouseActive, setIsMouseActive] = useState(false);

  const isSession = true;
  const markerRadius = 16; // kick marker radius

  const dragThreshold = 5; // Threshold in pixels

  // field landmark y values represented as a percentage
  const goalLine = kickType !== 5 ? 0.15659252465030957 : 0.0639625585;
  const twentyTwoMeterLine = kickType != 5 ? 0.5029373967321461 : 0.2550702028;

  // one meter represented as a percentage
  // full field = 0.008686711105
  const oneMeter = (twentyTwoMeterLine - goalLine) / 22;

  const kickInSelectedRestartZone = () => {
    const zone = restartZones.find((zone) => zone.id === restartZoneSelected);

    const kickX = position.x + markerRadius;
    const kickY = position.y + markerRadius;
    const kickInZone =
      kickX >= zone.x &&
      kickX <= zone.x + zone.w &&
      kickY >= zone.y &&
      kickY <= zone.y + zone.h;
    console.log("kick in selected zone: ", kickInZone);
    return kickInZone;
  };

  const getRestartResultType = (kick) => {
    console.log("restart kick pos x1: ", kick.x1);
    console.log("restart kick pos y1: ", kick.y1);
    console.log("restart not 10M: ", kick.y1 >= 0.75578231292517);
    if (kick.y1 >= 0.75578231292517) {
      return "Not 10M";
    } else if (kick.x1 <= 0.5828220858895705 || kick.x1 >= 0.9386503067484663) {
      return "Out on Full";
    }
    return "Missed Zone";
  };

  const getKickForTouchResultType = (y2) => {
    console.log("kick.y2: ", y2);
    console.log(
      "missed dead: ",
      y2 <= 0.10816326530612246 || y2 >= 0.9368174726989079
    );
    if (y2 <= 0.10816326530612246 || y2 >= 0.9368174726989079) {
      return "Missed Dead";
    }
    return "Missed Sideline";
  };

  const confirmKick = async (type) => {
    const success =
      type === 5 ? markerTwoIsOutOfBounds : kickInSelectedRestartZone();

    const distanceGained = success
      ? Math.floor(Math.abs(kickPosition.y1 - kickPosition.y2) / oneMeter)
      : 0;

    let resultType;
    // Restarts (type === 3) - set result type
    if (type === 3) {
      resultType = success ? "Made" : getRestartResultType(currentKick);
    } else if (type === 5) {
      resultType = success
        ? "Made"
        : getKickForTouchResultType(kickPosition.y2);
    } else {
      resultType = success ? "Made" : "Missed";
    }
    const kick = {
      ...currentKick,
      x2: kickPosition.x2,
      y2: kickPosition.y2,
      distanceGained,
      success,
      resultType,
    };
    setCurrentKick(kick);
    setShowDialog(false);

    console.log("create Kick...");
    const lastKick = await createKick(session, kick);
    console.log("lastKick: ", lastKick);
    setLastKick(lastKick);
  };

  const onImgLoad = () => {
    const marginX = kickType == 5 ? 0.2 : 0.0556;
    console.log(
      "[DEBUG] UPDATE THE INITIAL POSITION!!! KICK TYPE ID: ",
      kickType
    );
    updateInitialPosition(
      imgRef,
      marginX,
      setFieldCenter,
      setFieldZones,
      setKickPosition,
      setLoaded,
      setPosition,
      setRestartZones,
      null,
      isSession
    );
  };

  const distanceGained = useCallback(() => {
    const dist = Math.floor(
      Math.abs(kickPosition.y1 - kickPosition.y2) / oneMeter
    );

    setMetersGained(dist);
    return dist;
  }, [kickPosition.y1, kickPosition.y2, oneMeter, setMetersGained]);

  const distanceToPost = useCallback(() => {
    const rect = imgRef.current.getBoundingClientRect();
    const markerXOffset = markerRadius / rect.width;
    let a2 = Math.abs(0.5 - kickPosition.x1 + markerXOffset) / oneMeter;
    a2 *= a2;

    let b2;

    const markerYOffset = markerRadius / rect.height;
    b2 = Math.abs(kickPosition.y1 - goalLine + markerYOffset) / oneMeter;
    b2 *= b2;
    const dist = Math.sqrt(a2 + b2).toFixed(0);
    setDistanceToPost(dist);
    return dist;
  }, [goalLine, kickPosition.x1, kickPosition.y1, oneMeter, setDistanceToPost]);

  const distanceToFieldCenter = useCallback(() => {
    const rect = imgRef.current.getBoundingClientRect();
    const markerXOffset = markerRadius / rect.width;
    let a2 =
      Math.abs(fieldCenter.x - kickPosition.x1 + markerXOffset) / oneMeter;
    a2 *= a2;

    const markerYOffset = markerRadius / rect.height;
    let b2 =
      Math.abs(fieldCenter.y - kickPosition.y1 - markerYOffset) / oneMeter;
    b2 *= b2;
    const dist = Math.floor(Math.sqrt(a2 + b2));

    setDistanceToCenter(Math.abs(dist - 1));
    return dist;
  }, [
    fieldCenter.x,
    fieldCenter.y,
    kickPosition.x1,
    kickPosition.y1,
    oneMeter,
    setDistanceToCenter,
  ]);

  const distanceToTryLine = useCallback(() => {
    const rect = imgRef.current.getBoundingClientRect();
    const markerYOffset = markerRadius / rect.height;
    const dist = Math.abs(
      (kickPosition.y1 - goalLine + markerYOffset) / oneMeter
    ).toFixed(0);

    setDistanceToTryLine(dist);
    return dist;
  }, [kickPosition.y1, oneMeter, goalLine, setDistanceToTryLine]);

  const startDrag = (e) => {
    e.preventDefault();
    const isTouchEvent = e.type === "touchstart";
    const clientX = e.clientX || (isTouchEvent ? e.touches[0].clientX : 0);
    const clientY = e.clientY || (isTouchEvent ? e.touches[0].clientY : 0);

    if (isTouchEvent) {
      setIsTouchActive(true);
      setIsMouseActive(false);
    } else {
      setIsMouseActive(true);
      setIsTouchActive(false);
    }

    if (imgRef.current) {
      const rect = imgRef.current.getBoundingClientRect();
      const positionX = e.target.id === "marker1" ? position.x : position.x2;
      const positionY = e.target.id === "marker1" ? position.y : position.y2;

      const offsetX = clientX - rect.left - positionX;
      const offsetY = clientY - rect.top - positionY;

      setOffset({ x: offsetX, y: offsetY });
      setDragging(true);
      setStartPos({ x: clientX, y: clientY });
      // console.debug("Start drag:", { offsetX, offsetY, clientX, clientY, positionX, positionY });
    }
  };

  const onDrag = useCallback(
    (e) => {
      e.preventDefault();
      const isTouchEvent = e.type === "touchmove";
      const clientX = e.clientX || (isTouchEvent ? e.touches[0].clientX : 0);
      const clientY = e.clientY || (isTouchEvent ? e.touches[0].clientY : 0);

      if ((isTouchEvent && isTouchActive) || (!isTouchEvent && isMouseActive)) {
        if (dragging && imgRef.current) {
          const dx = clientX - startPos.x;
          const dy = clientY - startPos.y;
          if (Math.sqrt(dx * dx + dy * dy) > dragThreshold) {
            const rect = imgRef.current.getBoundingClientRect();
            const parentRect =
              imgRef.current.parentElement.getBoundingClientRect();

            let newX = clientX - rect.left - offset.x;
            let newY = clientY - rect.top - offset.y;

            const centerX = parentRect.width / 2;
            const fieldImageLeftmostX = centerX - rect.width / 2;
            const fieldImageRightmostX = centerX + rect.width / 2;

            // Ensure newX and newY stay within the image boundaries
            if (fieldImageLeftmostX > newX) {
              newX = fieldImageLeftmostX;
            } else if (fieldImageRightmostX < newX) {
              newX = fieldImageRightmostX - 32;
            }
            newY = Math.max(0, Math.min(newY, rect.height - 32));

            const kickPosX = (newX - fieldImageLeftmostX + 16) / rect.width;
            const kickPosY = (newY + 16) / rect.height;

            // console.debug("Dragging:", {
            //   target: e.target.id,
            //   newX,
            //   newY,
            //   clientX,
            //   clientY,
            //   dx,
            //   dy,
            // });

            // console.debug(`kickPosX: ${kickPosX}, kickPosY: ${kickPosY}`);

            const zone = getZone(fieldZones, newX + markerRadius);
            if (e.target.id === "marker1") {
              setMarkerOneIsOutOfBounds(zone === "Out of Bounds");
              setKickPosition((prevKickPos) => {
                const updatedKickPos = {
                  ...prevKickPos,
                  x1: kickPosX,
                  y1: kickPosY,
                };
                return updatedKickPos;
              });
              setPosition((prevPosition) => {
                const updatedPosition = {
                  ...prevPosition,
                  x: newX,
                  y: newY,
                };
                return updatedPosition;
              });
              distanceToFieldCenter();
              distanceToPost();
              distanceToTryLine();
              distanceGained();
            } else if (e.target.id === "marker2") {
              const topTryLinePx = goalLine * rect.height - 44;
              const bottomTryLinePx = rect.height - goalLine * rect.height + 16;
              const betweenTryLines =
                topTryLinePx < newY && newY < bottomTryLinePx;
              const successfulKickForTouch =
                zone === "Out of Bounds" && betweenTryLines;
              kickForTouchIsGood(successfulKickForTouch);
              setKickPosition((prevKickPos) => {
                const updatedKickPos = {
                  ...prevKickPos,
                  x2: kickPosX,
                  y2: kickPosY,
                };
                return updatedKickPos;
              });
              setPosition((prevPosition) => {
                const updatedPosition = {
                  ...prevPosition,
                  x2: newX,
                  y2: newY,
                };
                return updatedPosition;
              });
              distanceGained();
            }
          }
        }
      }
    },
    [
      dragging,
      goalLine,
      isMouseActive,
      isTouchActive,
      startPos.x,
      startPos.y,
      offset.x,
      offset.y,
      distanceGained,
      distanceToFieldCenter,
      distanceToPost,
      distanceToTryLine,

      fieldZones,
    ]
  );

  const endDrag = useCallback(
    (e) => {
      if (!dragging) return;
      if (e.type === "touchend") {
        setIsTouchActive(false);
      } else if (e.type === "mouseup") {
        setIsMouseActive(false);
      }
      setDragging(false);
    },
    [dragging]
  );

  const recordKick = () => {
    const _restartZone = restartZones.find(
      (area) => area.id === restartZoneSelected
    );
    if (!dragging && (!markerOneIsOutOfBounds || kickType === 3)) {
      const distToCenter = distanceToFieldCenter();
      const distToPost = distanceToPost();
      const distToTryLine = distanceToTryLine();
      const newKick = {
        x1: kickPosition.x1,
        y1: kickPosition.y1,
        kickType,
        distanceToCenter: distToCenter,
        distanceToPost: distToPost,
        distanceToTryLine: distToTryLine,
        kickingArea: _restartZone.kickingArea,
        player: player,
        success: false,
        resultType: "",
        zone: getZone(fieldZones, position.x + markerRadius),
        windDirection: "North",
        id: null,
      };
      setCurrentKick(newKick);
      setShowDialog(true);
    }
  };

  const handleDialogClick = async (success, resultType) => {
    let boxKick;
    if (kickType !== 4) {
      setCurrentKick((prevKick) => ({
        ...prevKick,
        success,
        resultType,
      }));
    } else {
      boxKick = {
        x1: 0.5,
        y1: 0.5,
        kickType,
        boxKickType: boxKickType.label,
        distanceToCenter: 0,
        distanceToPost: 22,
        distanceToTryLine: 22,
        player: player,
        success: success,
        resultType: resultType,
        windDirection: "North",
        id: null,
      };
      setCurrentKick(boxKick);
    }
    const kick =
      kickType !== 4 ? { ...currentKick, success, resultType } : boxKick; // kickType 4 is box kicks which are handled differently.
    setShowDialog(false);
    const rect = imgRef.current.getBoundingClientRect();
    const lastKick = await createKick(session, kick);
    setLastKick(lastKick);
  };

  const handleUndo = () => {
    setCurrentKick(null);
    setShowDialog(false);
  };

  useEffect(() => {
    window.addEventListener("mousemove", onDrag);
    window.addEventListener("mouseup", endDrag);
    window.addEventListener("touchmove", onDrag);
    window.addEventListener("touchend", endDrag);

    return () => {
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseup", endDrag);
      window.removeEventListener("touchmove", onDrag);
      window.removeEventListener("touchend", endDrag);
    };
  }, [onDrag, endDrag]);

  //[dragging, offset, restartZoneSelected, onDrag]);
  return (
    <>
      {loaded && (
        <div className="hidden">
          {distanceToFieldCenter()}
          {distanceToPost()}
          {distanceToTryLine()}
        </div>
      )}
      <div
        className="relative my-2 flex justify-center"
        onMouseUp={endDrag}
        onTouchEnd={endDrag}
      >
        <img
          ref={imgRef}
          src={src}
          alt="rugby field"
          className={`${kickType === 5 ? "w-[60%]" : "w-[90%]"} ${
            kickType === 4 && "hidden"
          }`}
          onLoad={onImgLoad}
          draggable="false"
        />
        {kickType === 3 &&
          restartZones &&
          restartZones.map(({ id, label, x, y, w, h }) => (
            <div
              key={id}
              style={{
                position: "absolute",
                left: `${x}px`,
                top: `${y}px`,
                width: w,
                height: h,
              }}
              className={`bg-slate-200 border ${
                restartZoneSelected === id
                  ? "border-kicker-green-700 border-2"
                  : "border-slate-500 border-dotted"
              }  flex justify-center items-center font-bold text-slate-400 text-md  `}
              onClick={() => {
                console.log("selected: ", id);
                setRestartZoneSelected(id);
              }}
            >
              {label}
            </div>
          ))}
        {kickType === 5 && (
          <svg className="absolute left-0 top-0 w-full h-full pointer-events-none">
            <line
              x1={position.x + markerRadius} // Adjusting for the center of the marker
              y1={position.y + markerRadius}
              x2={position.x2 + markerRadius}
              y2={position.y2 + markerRadius}
              stroke={markerTwoIsOutOfBounds ? "green" : "red"}
              strokeWidth="2"
            />
          </svg>
        )}
        {kickType !== 4 && (
          <div
            id="marker1"
            style={{
              position: "absolute",
              left: `${position.x}px`,
              top: `${position.y}px`,
            }}
            className={`touch-none w-8 h-8 border-2 text-white border-white ${
              markerOneIsOutOfBounds && kickType !== 3
                ? "bg-red-700"
                : "bg-green-700"
            } bg-opacity-70 rounded-full cursor-pointer flex justify-center items-center }`}
            onMouseDown={startDrag}
            onTouchStart={startDrag}
            onDoubleClick={recordKick}
          >
            +
          </div>
        )}
        {kickType === 5 && (
          <div
            id="marker2"
            style={{
              position: "absolute",
              left: `${position.x2}px`,
              top: `${position.y2}px`,
            }}
            className="touch-none w-8 h-8 border-2 border-white bg-slate-700 bg-opacity-70 rounded-full cursor-pointer flex justify-center items-center text-white"
            onMouseDown={startDrag}
            onTouchStart={startDrag}
            onDoubleClick={recordKick}
          >
            +
          </div>
        )}
        {(showDialog || kickType === 4) &&
          ([1, 2, 4].includes(kickType) ? (
            <div
              className={`w-full h-full flex justify-center items-center bg-black bg-opacity-50 ${
                kickType !== 4 ? "absolute top-0 left-0" : ""
              }`}
            >
              <div className="bg-gray-800 p-8 rounded-lg shadow-lg grid grid-cols-3 gap-4">
                {kickType === 4 && (
                  <button
                    className="col-span-3 bg-red-700 text-white text-xl p-4 rounded-lg border-4 border-red-600"
                    onClick={() => handleDialogClick(false, "Missed Long")}
                  >
                    MISS LONG
                  </button>
                )}
                <button
                  className="col-span-1 bg-red-700 text-white text-xl h-32 p-4 rounded-lg border-4 border-red-600"
                  onClick={() => handleDialogClick(false, "Missed Left")}
                >
                  MISS LEFT
                </button>
                <button
                  className="col-span-1 bg-green-700 text-white text-xl p-4 rounded-lg border-4 border-green-600"
                  onClick={() => handleDialogClick(true, "Made")}
                >
                  MADE
                </button>
                <button
                  className="col-span-1 bg-red-700 text-white text-xl p-4 rounded-lg border-4 border-red-600"
                  onClick={() => handleDialogClick(false, "Missed Right")}
                >
                  MISS RIGHT
                </button>
                <button
                  className="col-span-3 bg-red-700 text-white text-xl p-4 rounded-lg border-4 border-red-600"
                  onClick={() => handleDialogClick(false, "Missed Short")}
                >
                  MISS SHORT
                </button>
                <button
                  className="col-span-3 bg-gray-600 text-white text-xl p-4 rounded-lg mt-4 border-4 border-white"
                  onClick={handleUndo}
                >
                  UNDO
                </button>
              </div>
            </div>
          ) : (
            <div
              className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
              onClick={handleUndo}
            >
              <button
                className={`col-span-1 ${
                  (
                    kickType === 5
                      ? markerTwoIsOutOfBounds
                      : kickInSelectedRestartZone()
                  )
                    ? "bg-green-600"
                    : "bg-red-600"
                } text-white text-xl h-32 p-4 rounded-lg border-4 border-white`}
                onClick={(event) => {
                  event.stopPropagation();

                  confirmKick(kickType);
                }}
              >
                {(
                  kickType === 5
                    ? markerTwoIsOutOfBounds
                    : kickInSelectedRestartZone()
                )
                  ? "MADE"
                  : "MISSED"}
              </button>
            </div>
          ))}
      </div>
    </>
  );
};

export default KickDataEntryField1;
