import { useState, useEffect, useRef } from "react";
import DashboardButton from "../../Dashboard/components/DashboardButton";
import field1 from "../../../assets/images/session_field_1.png";
import field2 from "../../../assets/images/session_field_2.png";
import field3 from "../../../assets/images/session_field_3.png";
import field4 from "../../../assets/images/session_field_4.png";
import fullField from "../../../assets/images/full_field.png";

import undo from "../../../assets/images/undo.png";

import KickDataEntryField1 from "./KickDataEntryField1";
import { deleteKick } from "../../../utils/api";
import logo from "../../../assets/images/kicker_pro_logo.png";
import { useNavigate } from "react-router-dom";
import KickAddedTile from "../../Dashboard/components/KickAddedTile";

const KickDataEntry = ({ players, session }) => {
  const boxKickTypes = [
    { id: 1, label: "Contestable" },
    { id: 2, label: "Territory" },
  ];
  const kickTypes = [
    { id: 1, label: "Goal Kicks" },
    { id: 2, label: "Drop Kicks" },
    { id: 3, label: "Restarts" },
    { id: 4, label: "Box Kicks" },
    { id: 5, label: "Kick For Touch" },
  ];

  const [boxKickType, setBoxKickType] = useState(1);
  const [kickTypeId, setKickTypeId] = useState(1);
  const [distanceToCenter, setDistanceToCenter] = useState(0);
  const [distanceToPost, setDistanceToPost] = useState(0);
  const [distanceToTryLine, setDistanceToTryLine] = useState(0);
  const [kickSaved, setKickSaved] = useState(false);
  const [lastKick, setLastKick] = useState(null);
  const [metersGained, setMetersGained] = useState(0);
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(0);
  const playerListRef = useRef(null);

  const handleBoxKickTypeSelect = (id) => {
    setBoxKickType(id);
  };

  const handleKickTypeSelect = (id) => {
    setKickTypeId(id);
  };

  const handleLastKick = (lastKick) => {
    setLastKick(lastKick);
    setKickSaved(true);
  };

  const handleUndo = () => {
    deleteKick(lastKick.id);
    setLastKick(null);
  };

  const getFieldSrc = (kickTypeId) => {
    switch (kickTypeId) {
      case 1:
        return field1;
      case 2:
        return field2;
      case 3:
        return field3;
      case 4:
        return field4;
      case 5:
        return fullField;
      default:
        return field1;
    }
  };

  const navigate = useNavigate();
  const endSession = () => {
    localStorage.removeItem("activeSession");
    navigate("/");
  };

  // const handleKickSavedTime = async ()=>{
  //   timer = setTimeout(() => {
  //     setKickSaved(false);
  //   }, 3000); // Set kickSaved to false after 3 seconds
  //   await timer;
  //   return () => clearTimeout(timer); // Clean up the timer
  // }

  useEffect(() => {
    if (kickSaved) {
      let timer;
      timer = setTimeout(() => {
        setKickSaved(false);
      }, 3000); // Set kickSaved to false after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [kickSaved, kickTypeId, lastKick]);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <header className="w-full bg-kicker-green-50 flex flex-row justify-between p-2 items-center shadow-md ">
        <img className="pl-8 w-[200px]" src={logo} alt="Kicker Pro Logo" />
        <div className="flex items-center mr-4 px-4 py-2 border rounded-lg text-sm space-x-2 bg-red-600 text-white cursor-pointer">
          <a onClick={endSession}>End Session</a>
        </div>
      </header>
      <div className="flex bg-gray-200 divide-gray-300 divide-x pt-6 pb-96 min-h-screen">
        {/* Left column for Kick Types */}
        <div className="flex flex-col gap-4 pr-16 mt-8 ml-6 w-1/6 min-h-screen">
          {kickTypes.map(({ id, label }) => (
            <DashboardButton
              key={id}
              id={id}
              selected={kickTypeId === id}
              onSelect={handleKickTypeSelect}
            >
              {label}
            </DashboardButton>
          ))}
        </div>

        {/* Middle column for KickDataEntryField1 */}
        <div className="flex flex-col flex-1 min-h-screen">
          {/* Metrics */}
          <div className="flex my-2 items-center justify-end  mx-[5%]">
            {kickTypeId in [1, 2, 3] && (
              <div className="bg-white rounded-lg p-2 flex text-center divide-x ml-12">
                <div className="flex-col px-4">
                  <div className="font-bold">{distanceToTryLine}M</div>
                  <div className="text-xs text-slate-800">
                    FROM THE TRY LINE
                  </div>
                </div>
                <div className="flex-col px-4">
                  <div className="font-bold">{distanceToCenter}M</div>
                  <div className="text-xs text-slate-800">
                    FROM CENTRE FIELD
                  </div>
                </div>
                <div className="flex-col px-8">
                  <div className="font-bold">{distanceToPost}M</div>
                  <div className="text-xs text-slate-800">TO POST</div>
                </div>
              </div>
            )}

            {kickTypeId === 4 && (
              <div className="flex justify-between items-center space-x-4">
                {kickSaved && (
                  <KickAddedTile onClick={() => setKickSaved(false)} />
                )}
                {boxKickTypes.map(({ id, label }) => (
                  <DashboardButton
                    key={id}
                    id={id}
                    selected={boxKickType === id}
                    onSelect={handleBoxKickTypeSelect}
                  >
                    {label}
                  </DashboardButton>
                ))}
              </div>
            )}

            {kickTypeId === 5 && (
              <div className="bg-white rounded-lg p-2 flex text-center  ml-12">
                <div className="flex-col px-4">
                  <div className="font-bold">{metersGained}M</div>
                  <div className="text-xs text-slate-800">METERS GAINED</div>
                </div>
              </div>
            )}

            {/* Action Buttons */}
            <div className="flex">
              {lastKick && (
                <div
                  onClick={handleUndo}
                  className="bg-white rounded-lg flex-col w-12 h-12 justify-around items-center flex ml-4 p-2"
                >
                  <img src={undo} className="w-[15px]" alt="undo icon" />
                  <div className="text-xs text-slate-800">UNDO</div>
                </div>
              )}
            </div>
          </div>

          {/* KickDataEntryField */}
          <div className="flex justify-center">
            <KickDataEntryField1
              boxKickType={
                boxKickTypes.filter(({ id, label }) => id === boxKickType)[0]
              }
              kickType={kickTypeId}
              player={players[selectedPlayerIndex]}
              src={getFieldSrc(kickTypeId)}
              setDistanceToCenter={setDistanceToCenter}
              setDistanceToPost={setDistanceToPost}
              setDistanceToTryLine={setDistanceToTryLine}
              setMetersGained={setMetersGained}
              setLastKick={handleLastKick}
              session={session}
            />
          </div>
        </div>

        {/* Right Column for the PlayerList */}
        <div className="flex flex-col gap-4 w-1/6 min-h-screen  overflow-y-auto">
          <ul
            ref={playerListRef}
            className="flex flex-col items-center pt-6 pb-24"
          >
            {players?.map((player, index) => (
              <li
                key={index}
                onClick={() => setSelectedPlayerIndex(index)}
                className="flex flex-col items-center mb-4 snap-center"
              >
                <div
                  className={`rounded-full ${
                    index === selectedPlayerIndex
                      ? "border-kicker-green-700"
                      : "border-transparent"
                  } border-4 w-24 h-24 overflow-hidden flex justify-center items-center`}
                >
                  <img
                    src={player.image}
                    alt={player.name}
                    className={`object-cover min-w-full min-h-full bg-white ${
                      index === selectedPlayerIndex ? "" : "opacity-20"
                    }`}
                  />
                </div>
                <p
                  className={`text-center ${
                    index === selectedPlayerIndex ? "" : "text-gray-400"
                  }`}
                >
                  {player.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default KickDataEntry;
