import { useState, useEffect } from "react";
import Dropdown from "../../components/DropdownMenu";
import DashboardButton from "./components/DashboardButton";
import GoalKickStats from "./components/GoalKickStats";
import PlayerRankingList from "./components/PlayerRankings";
import RugbyUnionFieldChart from "./components/RugbyUnionFieldChart";
import DashboardLeftTile from "./components/TotalKicksMadePercentageTile";
import { getKicks, getPlayerProfiles } from "../../utils/api";
import DropdownMultiSelect from "../../components/DropdownMultiSelect";
import RecentSessions from "./components/RecentSessions";
import DateRangeFilter from "../../components/DateRangeFilter";
import LoadingIndicator from "../../components/LoadingIndicator";
import KickGraphBasic from "./components/KickGraphBasic";
import KickGraphMonthly from "./components/KickGraphMonthly";
import BoxKickGraph from "./components/BoxKickGraph";

function Dashboard() {
  const [data, setData] = useState([]);
  const [graphIndex, setGraphIndex] = useState(0);
  const [kickDataByPlayer, setKickDataByPlayer] = useState();
  const [playerProfiles, setPlayerProfiles] = useState();
  const [kickTypeId, setKickTypeId] = useState(1);
  const [startDateFilter, setStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();
  const [playersFilter, setPlayersFilter] = useState([]);
  const [sessionType, setSessionType] = useState(null);
  const [zone, setZone] = useState(null);
  const [waiting, setWaiting] = useState();

  const kickTypes = {
    1: "Goal Kicks",
    2: "Drop Kicks",
    3: "Restarts",
    4: "Box Kicks",
    5: "Kick for Touch",
  };

  const updateGraphIndexBack = () => {
    if (graphIndex > 0) {
      setGraphIndex(graphIndex - 1);
    }
  };
  const updateGraphIndexNext = () => {
    if (graphIndex < kickDataByPlayer.length - 1) {
      setGraphIndex(graphIndex + 1);
    }
  };

  const filters = [""];

  const [kickType, setKickType] = useState(kickTypes[0]);
  // Temporary Dummy Data
  const dropdownOptions = {
    sessions: ["All Sessions", "Training", "Games"],
    players: ["All Players", "Player 1", "Player 2"],
    zones: [
      "All Field Zones",
      "Left 5m",
      "Left 15m",
      "Center",
      "Right 15m",
      "Right 5m",
    ],
    times: ["Forever", "Past Year", "Past Month"],
  };

  const handleKickTypeSelect = (id) => {
    setWaiting(true);
    setKickType(kickTypes[id]);
    setKickTypeId(id);
  };
  const onSelect = (selections) => {
    setWaiting(true);
    setPlayersFilter(selections);
  };
  const setSessionTypeFilter = (index, sessionType) => {
    setWaiting(true);
    setSessionType(index === 0 ? null : index);
  };
  const setZoneFilter = (i, z) => {
    setWaiting(true);
    setZone(i === 0 ? null : z);
  };

  const processKicks = async (kicks, profiles) => {
    // Process and group kicks by player
    const kicksByPlayer = await kicks?.reduce((playerData, kick) => {
      if (kick.player) {
        if (!playerData[kick.player]) {
          playerData[kick.player] = {
            distanceGained: 0,
            totalKicks: 0,
            successfulKicks: 0,
            kicks: [],
          };
        }
        playerData[kick.player].kicks.push(kick);
        playerData[kick.player].totalKicks += 1;

        if (kick.success) {
          playerData[kick.player].distanceGained += kick.distance_gained;
          playerData[kick.player].successfulKicks += 1;
        }
      }
      return playerData;
    }, {});

    // Merge player profiles with their kicks data
    const mergedData = await profiles
      .map((profile) => {
        const playerKicks = kicksByPlayer[profile.id] || {
          totalKicks: 0,
          successfulKicks: 0,
        };

        return {
          ...profile,
          averageDistanceGained:
            playerKicks.successfulKicks > 0
              ? Math.ceil(
                  playerKicks.distanceGained / playerKicks.successfulKicks
                )
              : 0,
          kicks: playerKicks.kicks,
          missedKicks: playerKicks.totalKicks - playerKicks.successfulKicks,
          successfulKicks: playerKicks.successfulKicks,
          totalKicks: playerKicks.totalKicks,
          successRate:
            playerKicks.totalKicks > 0
              ? Math.round(
                  (playerKicks.successfulKicks / playerKicks.totalKicks) * 100
                )
              : 0,
        };
      })
      .sort((a, b) => b.successRate - a.successRate);
    return mergedData;
  };

  const fetchData = async () => {
    setWaiting(true);
    setGraphIndex(0);
    setData([]); // Reset data to an empty array before fetching
    const kicks = await getKicks(
      kickTypeId,
      startDateFilter,
      endDateFilter,
      playersFilter,
      sessionType,
      zone
    );

    let _playerProfiles;
    if (!playerProfiles) {
      _playerProfiles = await getPlayerProfiles();
      setPlayerProfiles(_playerProfiles);
    } else {
      _playerProfiles = playerProfiles;
    }

    if (playersFilter?.length) {
      console.log("helllpp!: ", playersFilter);
      _playerProfiles = _playerProfiles.filter((player) =>
        playersFilter.includes(player.id)
      );
    }
    const _kickDataByPlayer = await processKicks(kicks, _playerProfiles);

    setWaiting(false);
    setKickDataByPlayer(_kickDataByPlayer);
    setData(kicks);
  };

  useEffect(() => {
    setData();
    fetchData();
  }, [
    kickTypeId,
    startDateFilter,
    endDateFilter,
    playersFilter,
    sessionType,
    zone,
  ]);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 ml-8 mt-8">
        <DashboardButton
          id={1}
          selected={kickTypeId === 1}
          onSelect={handleKickTypeSelect}
        >
          Goal Kicks
        </DashboardButton>
        <DashboardButton
          id={2}
          selected={kickTypeId === 2}
          onSelect={handleKickTypeSelect}
        >
          Drop Kicks
        </DashboardButton>
        <DashboardButton
          id={3}
          selected={kickTypeId === 3}
          onSelect={handleKickTypeSelect}
        >
          Restarts
        </DashboardButton>
        <DashboardButton
          id={4}
          selected={kickTypeId === 4}
          onSelect={handleKickTypeSelect}
        >
          Box Kicks
        </DashboardButton>
        <DashboardButton
          id={5}
          selected={kickTypeId === 5}
          onSelect={handleKickTypeSelect}
        >
          Kick For Touch
        </DashboardButton>
      </div>
      <div className="grid grid-cols-12 gap-4 ml-8 mt-8 rounded-lg">
        <Dropdown
          label="All Sessions"
          options={dropdownOptions.sessions}
          onSelect={setSessionTypeFilter}
        />
        <DropdownMultiSelect
          playerFilter={true}
          label="All Players"
          onSelect={onSelect}
        />
        <Dropdown
          label="All Field Zones"
          options={dropdownOptions.zones}
          onSelect={setZoneFilter}
        />
        <DateRangeFilter
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
        />
      </div>
      {waiting ? (
        <LoadingIndicator />
      ) : (
        <div className="m-4 grid gap-4 grid-cols-12 flex-col">
          <div className="col-span-9 min-h-[100px]">
            <div className="m-4 grid grid-cols-2 gap-4">
              <div className="min-h-[300px]">
                <DashboardLeftTile kicks={data} kickTypeId={kickTypeId} />
              </div>
              <div className="min-h-[300px]">
                <GoalKickStats kicks={data} />
              </div>
              <div className="col-span-2 min-h-[600px] ">
                {kickTypeId !== 4 ? (
                  <RugbyUnionFieldChart kicks={data} kickTypeId={kickTypeId} />
                ) : (
                  <BoxKickGraph data={data} />
                )}
              </div>
            </div>
          </div>
          <div className="col-span-3">
            {kickDataByPlayer && kickDataByPlayer[graphIndex] && (
              <>
                <KickGraphBasic
                  data={kickDataByPlayer[graphIndex]}
                  kickTypeId={kickTypeId}
                />
                {kickTypeId === 1 && (
                  <KickGraphMonthly data={kickDataByPlayer[graphIndex]} />
                )}
              </>
            )}
            {kickDataByPlayer?.length ?? 0 > 1 ? (
              <div
                className={`flex justify-end w-full ${
                  kickTypeId === 1 ? "pt-2" : null
                }`}
              >
                <button
                  onClick={updateGraphIndexBack}
                  disabled={graphIndex === 0} // Disable if index is 0
                  className={`bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow ${
                    graphIndex === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-100"
                  }`} // Apply styles to indicate the button is inactive
                >
                  Back
                </button>
                <button
                  onClick={updateGraphIndexNext}
                  disabled={graphIndex === kickDataByPlayer.length - 1} // Disable if index equals data.length - 1
                  className={`bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow ml-2 ${
                    graphIndex === kickDataByPlayer.length - 1
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-100"
                  }`} // Apply styles to indicate the button is inactive
                >
                  Next
                </button>
              </div>
            ) : (
              <></>
            )}
            <div className="col-span-3 min-h-[400px] rounded ">
              {kickDataByPlayer && (
                <PlayerRankingList
                  players={kickDataByPlayer}
                  kickTypeId={kickTypeId}
                />
              )}
              <RecentSessions kicks={data} playersFilter={playersFilter} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
