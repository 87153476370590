import React from "react";

const sizeVariants = {
  xs: {
    fontWeight: "100",
    percentFontSize: "10px",
    subtitleFontSize: "8px",
    strokeWidth: 2,
  },
  sm: {
    fontWeight: "200",
    percentFontSize: "12px",
    subtitleFontSize: "8px",
    strokeWidth: 4,
  },
  md: {
    fontWeight: "200",
    percentFontSize: "16px",
    subtitleFontSize: "10px",
    strokeWidth: 6,
  },
  lg: {
    fontWeight: "200",
    percentFontSize: "20px",
    subtitleFontSize: "12px",
    strokeWidth: 8,
  },
  xl: {
    fontWeight: "200",
    percentFontSize: "24px",
    subtitleFontSize: "14px",
    strokeWidth: 8,
  },
};

const CircularProgress = ({
  percent,
  subtitle = "",
  variant = "md",
  size = 100,
  percentColor = "#000",
  primary = "black",
  secondary = "lightgray",
  showMeters = false,
  subtitleColor = "#333",
  dy = "-0.2em",
}) => {
  const { percentFontSize, subtitleFontSize, strokeWidth, fontWeight } =
    sizeVariants[variant] || sizeVariants.md;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (percent / 100) * circumference;
  const center = size / 2;
  const rotationTransform = `rotate(-90 ${center} ${center})`;
  return (
    <svg width={size} height={size}>
      <circle
        stroke={secondary}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={center}
        cy={center}
      />
      <circle
        stroke={primary}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset: offset }}
        r={radius}
        cx={center}
        cy={center}
        transform={rotationTransform}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        stroke={percentColor}
        dy={dy}
        fontSize={percentFontSize}
        fontWeight={fontWeight}
      >
        {`${Math.round(percent)}${showMeters ? "m" : "%"}`}
      </text>
      {subtitle && (
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          stroke={subtitleColor}
          dy="1.5em" // Adjust based on your needs
          fontSize={subtitleFontSize}
          fontWeight={fontWeight}
          className={subtitleFontSize}
        >
          {subtitle}
        </text>
      )}
    </svg>
  );
};

export default CircularProgress;
