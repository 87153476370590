import React, { useEffect, useState } from "react";
import { getPlayerKickSummaries } from "../../utils/api";
import DashboardButton from "../Dashboard/components/DashboardButton";
import DropdownMultiSelect from "../../components/DropdownMultiSelect";
import Dropdown from "../../components/DropdownMenu";
import DateRangeFilter from "../../components/DateRangeFilter";

const commonStatTypes = [
  { key: "successRate", label: "SUCCESS RATE" },
  { key: "totalKicks", label: "TOTAL KICKS" },
  { key: "totalSessions", label: "TOTAL SESSIONS" },
];

const zoneStatTypes = [
  { key: "left5MZone", label: "LEFT 5M ZONE" },
  { key: "left15MZone", label: "LEFT 15M ZONE" },
  { key: "centreZone", label: "CENTRE ZONE" },
  { key: "right15MZone", label: "RIGHT 15M ZONE" },
  { key: "right5MZone", label: "RIGHT 5M ZONE" },
];

const over40MStatTypes = [
  { key: "totalMadeOver40M", label: "TOTAL MADE OVER 40M" },
];

const dropAndGoalKickStats = [
  ...commonStatTypes,
  ...zoneStatTypes,
  ...over40MStatTypes,
];

const statTypesByKickType = {
  1: dropAndGoalKickStats,
  2: dropAndGoalKickStats,
  3: [
    ...commonStatTypes,
    { key: "c_1", label: "ZONE 1" },
    { key: "l_2", label: "LEFT ZONE 2" },
    { key: "l_3", label: "LEFT ZONE 3" },
    { key: "l_4", label: "LEFT ZONE 4" },
    { key: "r_2", label: "RIGHT ZONE 2" },
    { key: "r_3", label: "RIGHT ZONE 3" },
    { key: "r_4", label: "RIGHT ZONE 4" },
    { key: "c_5", label: "Zone 5" },
  ],
  4: [
    ...commonStatTypes,
    { key: "contestable", label: "CONTESTABLE" },
    { key: "territory", label: "TERRITORY" },
  ],
  5: [
    { key: "averageMetreGain", label: "AVERAGE METRE GAIN" },
    ...commonStatTypes,
    ...zoneStatTypes,
  ],
};

const getClassForRank = (rank) => {
  if (rank === 1) return "bg-gold bg-opacity-20";
  if (rank === 2) return "bg-silver bg-opacity-20";
  if (rank === 3) return "bg-bronze bg-opacity-20";
  return "";
};

const CompareProfiles = () => {
  const [data, setData] = useState([]);
  const [kickTypeId, setKickTypeId] = useState(1);
  const [startDateFilter, setStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();
  const [playersFilter, setPlayersFilter] = useState([]);
  const [sessionType, setSessionType] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const kickTypes = {
    1: "Goal Kicks",
    2: "Drop Kicks",
    3: "Restarts",
    4: "Box Kicks",
    5: "Kick for Touch",
  };

  const dropdownOptions = {
    sessions: ["All Sessions", "Training", "Games"],
  };

  const handleKickTypeSelect = (id) => {
    setKickTypeId(id);
  };

  const onSelect = (selections) => {
    setPlayersFilter(selections);
  };

  const setSessionTypeFilter = (index, sessionType) => {
    setSessionType(index === 0 ? null : index);
  };

  const transformData = (summaries) => {
    return summaries.map((summary) => {
      const stats = {
        successRate: summary.success_rate,
        totalKicks: summary.total_kicks,
        totalSessions: summary.total_sessions,
        left5MZone: summary.kicks_by_zone?.["Left 5m"]?.success_rate || 0,
        left15MZone: summary.kicks_by_zone?.["Left 15m"]?.success_rate || 0,
        centreZone: summary.kicks_by_zone?.["Center"]?.success_rate || 0,
        right15MZone: summary.kicks_by_zone?.["Right 15m"]?.success_rate || 0,
        right5MZone: summary.kicks_by_zone?.["Right 5m"]?.success_rate || 0,
        c_1: summary.kicks_by_kicking_area?.["c_1"]?.success_rate || 0,
        l_2: summary.kicks_by_kicking_area?.["l_2"]?.success_rate || 0,
        l_3: summary.kicks_by_kicking_area?.["l_3"]?.success_rate || 0,
        l_4: summary.kicks_by_kicking_area?.["l_4"]?.success_rate || 0,
        r_2: summary.kicks_by_kicking_area?.["r_2"]?.success_rate || 0,
        r_3: summary.kicks_by_kicking_area?.["r_3"]?.success_rate || 0,
        r_4: summary.kicks_by_kicking_area?.["r_4"]?.success_rate || 0,
        c_5: summary.kicks_by_kicking_area?.["c_5"]?.success_rate || 0,
        contestable:
          summary.kicks_by_box_kick_type?.["Contestable"]?.success_rate || 0,
        territory:
          summary.kicks_by_box_kick_type?.["Territory"]?.success_rate || 0,
        totalMadeGame:
          summary.kicks_by_session_type?.["Game"]?.success_rate || 0,
        totalMadeTraining:
          summary.kicks_by_session_type?.["Training"]?.success_rate || 0,
        totalMadeOver40M: summary.kicks_over_40m?.success_rate || 0,
        kicksByKickingArea: summary.kicks_by_kicking_area || 0,
        kicksByBoxKickType: summary.kicks_by_box_kick_type || 0,
        averageMetreGain: summary.average_metre_gain || 0,
      };

      return {
        name: summary.player_profile.name,
        image: summary.player_profile.image,
        stats,
      };
    });
  };

  const renderStats = (statKey) => {
    const metreKeys = ["averageMetreGain"];
    const percentageKeys = [
      "successRate",
      "left5MZone",
      "left15MZone",
      "centreZone",
      "right5MZone",
      "right15MZone",
      "c_1",
      "l_2",
      "l_3",
      "l_4",
      "r_2",
      "r_3",
      "r_4",
      "c_5",
      "contestable",
      "territory",
      "totalMadeGame",
      "totalMadeTraining",
      "totalMadeOver40M",
      "kicksByKickingArea",
      "kicksByBoxKickType",
    ];
    const values = data.map((player) => player.stats[statKey]);
    const sortedValues = [...values].sort((a, b) => b - a);
    const ranks = values.map((value) => sortedValues.indexOf(value) + 1);

    return data.map((player, index) => (
      <td key={player.name}>
        <div
          className={`w-12 h-12 flex items-center justify-center text-slate-600 text-xs text-center rounded-md mx-auto ${getClassForRank(
            ranks[index]
          )}`}
        >
          {percentageKeys.includes(statKey)
            ? `${parseFloat(player.stats[statKey]).toFixed(1)}%`
            : metreKeys.includes(statKey)
            ? `${player.stats[statKey].toFixed(0)}M`
            : player.stats[statKey]}
        </div>
      </td>
    ));
  };

  const fetchData = async () => {
    setWaiting(true);
    const summaries = await getPlayerKickSummaries(
      kickTypeId,
      startDateFilter,
      endDateFilter,
      playersFilter,
      sessionType
    );
    const transformedData = transformData(summaries);
    setWaiting(false);
    setData(transformedData);
  };

  useEffect(() => {
    fetchData();
  }, [kickTypeId, startDateFilter, endDateFilter, playersFilter, sessionType]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-4 ml-8 mt-8">
        <DashboardButton
          id={1}
          selected={kickTypeId === 1}
          onSelect={handleKickTypeSelect}
        >
          Goal Kicks
        </DashboardButton>
        <DashboardButton
          id={2}
          selected={kickTypeId === 2}
          onSelect={handleKickTypeSelect}
        >
          Drop Kicks
        </DashboardButton>
        <DashboardButton
          id={3}
          selected={kickTypeId === 3}
          onSelect={handleKickTypeSelect}
        >
          Restarts
        </DashboardButton>
        <DashboardButton
          id={4}
          selected={kickTypeId === 4}
          onSelect={handleKickTypeSelect}
        >
          Box Kicks
        </DashboardButton>
        <DashboardButton
          id={5}
          selected={kickTypeId === 5}
          onSelect={handleKickTypeSelect}
        >
          Kick For Touch
        </DashboardButton>
      </div>
      <div className="grid grid-cols-12 gap-4 ml-8 mt-8 rounded-lg">
        <Dropdown
          label="All Sessions"
          options={dropdownOptions.sessions}
          onSelect={setSessionTypeFilter}
        />
        <DropdownMultiSelect
          playerFilter={true}
          label="All Players"
          onSelect={onSelect}
        />
        <DateRangeFilter
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
        />
      </div>
      <div className="m-24">
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th></th>
                {data.map((player) => (
                  <th key={player.name} className="p-2">
                    <img
                      src={player.image}
                      alt={player.name}
                      className="w-10 h-10 rounded-full mx-auto"
                    />
                    <div className="text-sm text-center font-normal">
                      {player.name}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {statTypesByKickType[kickTypeId].map((stat) => (
                <React.Fragment key={stat.key}>
                  <tr>
                    <td className="p-2">{stat.label}</td>
                    {renderStats(stat.key)}
                  </tr>
                  <tr>
                    <td colSpan={data.length + 1}>
                      <hr className="my-2 border-t border-gray-200" />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompareProfiles;
