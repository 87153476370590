import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import CircularProgress from "../../../components/CircularProgress";
import { useEffect, useState } from "react";

export default function BoxKickGraph({ data }) {
  const [chartConfig, setChartConfig] = useState();

  const initializeChart = async (kicks) => {
    console.log("[DEBUG] BOX KICK GRAPH: ", kicks?.length);
    if (!kicks) {
      setChartConfig(null);
      return;
    }
    const groupedData = await kicks?.reduce((acc, kick) => {
      const { result_type } = kick;

      if (!acc[result_type]) {
        acc[result_type] = 0;
      }

      acc[result_type] += 1;

      return acc;
    }, {});

    if (!Object.values(groupedData)?.length) {
      setChartConfig(null);
      return;
    }
    const kickData = {
      kicks: Object.values(groupedData), // Array of counts for each result type
      categories: Object.keys(groupedData), // Array of result types
    };

    const _chartConfig = {
      type: "bar",
      height: "400px", // Set chart height to auto
      series: [
        {
          name: "Kicks",
          data: kickData.kicks,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#020617"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 6,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "10px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: kickData.categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 5,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          theme: "dark",
        },
      },
    };
    setChartConfig(_chartConfig);
  };

  useEffect(() => {
    initializeChart(data);
  }, [data]);

  return (
    <div className="col-span-3 mb-2">
      <Card className=" h-auto rounded-lg">
        <CardBody className="px-2 pb-0">
          {chartConfig ? <Chart {...chartConfig} /> : <></>}
        </CardBody>
      </Card>
    </div>
  );
}
