import { useEffect, useState } from "react";
import { createSession } from "../../../utils/api";

const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={3}
    stroke="white"
    className="w-4 h-4 mr-4"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
);

const PlayerSelection = ({
  changeStep,
  players,
  selectedSession,
  setSession,
  updateSelectedPlayers,
}) => {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");

  const updateSelections = (index) => {
    const isSelected = selectedPlayers.includes(index);
    const updatedList = isSelected
      ? selectedPlayers.filter((i) => i !== index)
      : [...selectedPlayers, index];

    setSelectedPlayers(updatedList);
  };

  const startSession = async () => {
    const sessionType = selectedSession === "Training" ? 1 : 2;
    const playerIds = selectedPlayers.map((i) => players[i].id);
    const session = await createSession(sessionType, playerIds);
    // TODO add some error handling if createSession fails.
    setSession(session);
    changeStep(3);
  };

  useEffect(() => {
    updateSelectedPlayers(selectedPlayers);
  }, [selectedPlayers]);

  return (
    <div>
      <div className="max-h-96 w-[500px] bg-white border ml-2 rounded-lg shadow-md p-4 mb-8 overflow-y-auto">
        <ul>
          {players?.map((player, index) => (
            <li
              key={index}
              onClick={() => updateSelections(index)}
              className={`m-4 p-4 flex items-center justify-between ${
                selectedPlayers.includes(index)
                  ? "bg-kicker-green-700"
                  : "bg-grey-200"
              }  rounded-lg border-black border-[1px]`}
            >
              <div className="flex items-center">
                {selectedPlayers.includes(index) && checkmark}
                <div className="rounded-full  w-10 h-10 mr-4 overflow-hidden flex justify-center items-center">
                  <img
                    src={player.image}
                    alt={player.name}
                    className="object-cover min-w-full min-h-full"
                  />
                </div>

                <div>
                  <p
                    className={
                      selectedPlayers.includes(index) ? "text-white" : ""
                    }
                  >
                    {player.name}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <button
        className="absolute bottom-32 left-16 bg-kicker-green-700 text-white px-6 py-2 rounded-lg shadow"
        onClick={() => changeStep(1)}
      >
        Back
      </button>
      {warningMessage && (
        <div className="text-red-500 text-center mb-4">{warningMessage}</div>
      )}
      <button
        className="absolute bottom-32 right-16 bg-kicker-green-700 text-white px-6 py-2 rounded-lg shadow"
        onClick={() => {
          if (selectedPlayers.length > 0) {
            startSession();
            setWarningMessage(""); // Clear any existing warning message
            return;
          }
          setWarningMessage("Please select at least one player.");
          console.log("No Player Selected");
        }}
      >
        Next
      </button>
    </div>
  );
};

export default PlayerSelection;
