import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { isValidToken } from "../utils/auth";

const Layout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSessionActive, setIsSessionActive] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    setIsLoggedIn(false);
    navigate("/login", { replace: true });
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  useEffect(() => {
    setIsLoggedIn(isValidToken());
    const activeSession = localStorage.getItem("activeSession") === "true";
    setIsSessionActive(activeSession);
  }, []);

  const startSession = () => {
    setIsSessionActive(true);
    localStorage.setItem("activeSession", "true");
  };

  const endSession = () => {
    setIsSessionActive(false);
    localStorage.removeItem("activeSession");
  };

  return (
    <>
      <NavBar
        isLoggedIn={isLoggedIn}
        handleLogout={handleLogout}
        isSessionActive={isSessionActive}
      />
      <Outlet context={{ handleLogin, startSession, endSession }} />
      <Footer />
    </>
  );
};

export default Layout;
