import { useEffect, useState } from "react";

function GoalKickStats({ kicks }) {
  const [totalKicks, setTotalKicks] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);

  useEffect(() => {
    function calculate() {
      setTotalKicks(kicks?.length ?? 0);
      const uniqueSessions = new Set();

      kicks.forEach((kick) => {
        if (kick.session !== null && kick.session !== undefined) {
          uniqueSessions.add(kick.session);
        }
      });

      setTotalSessions(uniqueSessions.size);
    }
    calculate();
  }, [kicks]);

  return (
    <div className="h-full p-8 bg-gray-100 border rounded-lg flex justify-center items-center ">
      <div className="flex flex-col justify-center items-center text-center">
        <h2 className="text-[28px] font-bold">{totalKicks}</h2>
        <h2 className="text-sm">TOTAL KICKS</h2>
        <h2 className="text-[28px] font-bold">{totalSessions}</h2>
        <h2 className="text-sm">TOTAL SESSIONS</h2>
      </div>
    </div>
  );
}

export default GoalKickStats;
