import React from "react";
import CircularProgress from "../../../components/CircularProgress";

const StatIndicator = ({ label, average }) => {
  return (
    <div className="flex flex-col items-center">
      <CircularProgress
        percent={(average / 100) * 100}
        size={60}
        strokeWidth={5}
        variant="sm"
        percentColor="#FFF"
        primary="#FFF"
        secondary="#85AA80"
        showMeters={true}
        dy="0.5em"
      />
      <p className="text-white text-sm mt-1">{label.toUpperCase()}</p>
    </div>
  );
};

const ZoneDistanceAverages = ({ kickTypeId, stats }) => {
  return (
    <div className="flex justify-between w-full mb-4">
      {stats.map((zone) => {
        return (
          <StatIndicator
            key={zone.label}
            label={zone.label}
            average={zone.average_gain}
          />
        );
      })}
    </div>
  );
};

export default ZoneDistanceAverages;
