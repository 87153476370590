import React, { useState } from "react";
import "./Login.css";
import { login } from "../../utils/api";
import { useNavigate, useOutletContext } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const { handleLogin } = useOutletContext();
  const loginHandler = async (event) => {
    event.preventDefault();
    const loginSuccessful = await login(email, password);
    if (loginSuccessful) {
      handleLogin();
      navigate("/");
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="text-left py-8">
          <p className="text-gray-400 text-xs">Organisation</p>
          <p className="text-lg font-bold">Japan Rugby Football Union</p>
        </div>
      </div>
      <hr className="border-t border-gray-100 mx-12"></hr>
      <div className="login-container">
        <main>
          <div className="login-card rounded">
            <h2>Welcome Back!</h2>
            <p>Please login to continue.</p>
            <form className="space-y-4" onSubmit={loginHandler}>
              <input
                type="email"
                placeholder="email"
                className="w-full mb-2 outline-none rounded p-2 bg-slate-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="password"
                className="w-full mb-2 outline-none rounded p-2 bg-slate-100"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-slate-800 hover:bg-slate-500 text-white font-bold m-[9px] py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Login
                </button>
                <a
                  href="/forgot-password"
                  className="inline-block align-baseline font-bold text-sm text-slate-800 hover:text-slate-500 mr-[9px]"
                >
                  forgot password?
                </a>
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Login;
