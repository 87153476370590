import React, { useEffect, useState } from "react";
import SessionTypeSelection from "./components/SessionTypeSelection";
import PlayerSelection from "./components/PlayerSelection";
import { getPlayerProfiles } from "../../utils/api";
import KickDataEntry from "./components/KickDataEntry";
import { useOutletContext } from "react-router-dom";

const Session = () => {
  const [selectedSessionType, setSelectedSessionType] = useState("Training"); // default selection
  const [step, setStep] = useState(1);
  const [players, setPlayers] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [session, setSession] = useState(null);

  const { startSession } = useOutletContext();

  const toggleSession = (session) => {
    setSelectedSessionType(session);
  };

  const changeStep = (step) => {
    setStep(step);
  };

  const updatedSelectedPlayers = (selectedIndexes) => {
    setSelectedPlayers(selectedIndexes);
  };

  const getPlayers = async () => {
    const res = await getPlayerProfiles();
    setPlayers(res);
  };

  useEffect(() => {
    getPlayers();
  }, []);

  useEffect(() => {
    if (session) {
      startSession(); // Mark session as active
    }
  }, [session, startSession]);

  return step !== 3 ? (
    <div className="w-full h-screen bg-slate-700 flex items-center justify-center pb-32">
      {players ? (
        <>
          {step === 1 && (
            <SessionTypeSelection
              changeStep={changeStep}
              selectedSession={selectedSessionType}
              toggleSession={toggleSession}
            />
          )}
          {step === 2 && (
            <PlayerSelection
              changeStep={changeStep}
              players={players}
              selectedSession={selectedSessionType}
              setSession={setSession}
              updateSelectedPlayers={updatedSelectedPlayers}
            />
          )}
        </>
      ) : (
        <div className="text-white text-sm">loading....</div>
      )}
    </div>
  ) : (
    <KickDataEntry
      players={selectedPlayers.map((i) => players[i])}
      session={session}
    />
  );
};

export default Session;
