import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns"; // Import the format function
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangeFilter = ({ setStartDateFilter, setEndDateFilter }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [picking, setPicking] = useState(false);

  // Ref to store the DatePicker component instance
  const datePickerRef = useRef();

  const handleChange = ([start, end]) => {
    setStartDate(start);
    setStartDateFilter(start);
    setEndDate(end);
    setEndDateFilter(end);
    // Only close the picker if both start and end dates are selected
    if (start && end) {
      setPicking(false);
    }
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
    // Optionally close the picker here as well
    // setPicking(false);
  };

  // Format the displayed date or date range
  const displayDate = () => {
    if (!startDate && !endDate) {
      return "Forever";
    }
    if (startDate && endDate) {
      // Format both start and end dates
      return `${format(startDate, "MM/dd/yyyy")} - ${format(
        endDate,
        "MM/dd/yyyy"
      )}`;
    }
    // If only one date is selected (though for a range picker this might be rare)
    return format(startDate || endDate, "MM/dd/yyyy");
  };

  const buttonStyle =
    "inline-flex justify-between items-center w-full h-9 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue";

  // Effect to automatically open the DatePicker dialog
  useEffect(() => {
    if (picking && datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  }, [picking]);

  return (
    <div className="relative col-span-2 text-left">
      {picking ? (
        <DatePicker
          ref={datePickerRef}
          className={buttonStyle}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          isClearable
          shouldCloseOnSelect={false}
          open={true}
          onClickOutside={() => setPicking(false)}
          //withPortal
        />
      ) : (
        <>
          <button className={buttonStyle} onClick={() => setPicking(true)}>
            {displayDate()}
          </button>
          {(startDate || endDate) && (
            <button
              onClick={clearDates}
              className="mt-2 ml-2 text-xs text-blue-500 hover:text-blue-700"
            >
              Clear Dates
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default DateRangeFilter;
