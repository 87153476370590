import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Footer() {
  const location = useLocation();
  return (
    <footer
      className={`fixed inset-x-0 bottom-0 w-full bg-kicker-green-50 flex flex-row justify-between p-8 items-center shadow-md ${
        location.pathname === "/session" && "hidden"
      }`}
      style={{ zIndex: 99 }}
    >
      <div className="flex text-slate-400 text-sm">
        <p>Kicker Pro©</p>
        <p className="pl-20">All Rights Reserved 2023</p>
      </div>
    </footer>
  );
}

export default Footer;
