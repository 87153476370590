// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
}

header h1 {
  font-size: 24px;
  margin-bottom: 0;
}

header h2,
header h3 {
  font-size: 14px;
  margin: 5px 0;
}

.login-card {
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.login-card h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.login-card p {
  font-size: 16px;
  color: #666;
}

.login-card form {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".login-container {\n  font-family: \"Arial\", sans-serif;\n  color: #333;\n  max-width: 400px;\n  margin: 50px auto;\n  text-align: center;\n}\n\nheader h1 {\n  font-size: 24px;\n  margin-bottom: 0;\n}\n\nheader h2,\nheader h3 {\n  font-size: 14px;\n  margin: 5px 0;\n}\n\n.login-card {\n  background: #fff;\n  padding: 20px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  margin-top: 20px;\n}\n\n.login-card h2 {\n  font-size: 22px;\n  margin-bottom: 10px;\n}\n\n.login-card p {\n  font-size: 16px;\n  color: #666;\n}\n\n.login-card form {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
