import React, { useState } from "react";
import { uploadProfileImage } from "../../../utils/api";

function ProfileImage({ profile }) {
  const [image, setImage] = useState(profile.image);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("profile: ", profile);
    const imgUrl = await uploadProfileImage(file, profile.user);
    if (imgUrl.startsWith("http")) {
      setImage(imgUrl);
    }
  };

  return (
    <div className="rounded-full bg-gray-300 w-10 h-10 mr-4 overflow-hidden flex justify-center items-center">
      <img
        src={image}
        alt={`${profile.name}'s photo`}
        className="object-cover min-w-full min-h-full"
        onClick={() =>
          document.getElementById(`fileInput-${profile.user}`).click()
        }
      />
      <input
        type="file"
        id={`fileInput-${profile.user}`}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
    </div>
  );
}

export default ProfileImage;
